.signup {
  background: #1A2038;
  

  .signup-card {
    max-width: 800px;
    border-radius: 12px !important;
    img {
      width: 200px;
    }
  }

  .signup-card-customs {
    max-width: 1000px;
    border-radius: 12px !important;
    img {
      width: 200px;
    }
  }
}
